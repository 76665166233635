import { useState } from 'react';
import { useRouter } from 'next/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MIN_VALUE } from '@constants';
import { Alert, Body, Button, Heading, Input } from '@components';
import useActivateCustomer from '@services/shopify/operations/activate-customer';
import styles from './LoginForm.module.scss';

type ActiveAccountFormValues = {
	password: string;
	confirmPassword: string;
};

const ActiveAccountForm = () => {
	const router = useRouter();
	const activateCustomer = useActivateCustomer();

	const [customerId, activationToken] = router.query.urlInfo;

	const {
		register,
		reset,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm<ActiveAccountFormValues>({
		mode: 'onBlur',
		defaultValues: {
			password: '',
			confirmPassword: '',
		},
	});

	const [submitErrors, setSubmitErrors] = useState([]);
	const currentPasswordInput = watch('password');
	const confirmPasswordInput = watch('confirmPassword');
	const [loading, setLoading] = useState(false);
	const [accountActivated, setAccountActivated] = useState(false);

	const onSubmit: SubmitHandler<ActiveAccountFormValues> = async data => {
		const customerGID = `gid://shopify/Customer/${customerId}`;

		try {
			setLoading(true);
			await activateCustomer(customerGID, { activationToken, password: data.password });
			setAccountActivated(true);
			router.push('/');
		} catch (error) {
			console.error('Error activating account:', error);
			setLoading(false);
			reset();
			setAccountActivated(false);
			return setSubmitErrors(error.errors);
		}
	};

	return (
		<form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
			<Heading tag='h1' style={{ marginBottom: '2.4rem' }}>
				Activate Account
			</Heading>
			<Body style={{ marginBottom: '2.4rem' }}>Create your password to activate your account.</Body>
			{!!submitErrors.length &&
				submitErrors.map(({ message }, index) => {
					return <Alert message={message} key={index} />;
				})}
			{accountActivated && <Alert info message='Your account is being activated!' />}
			<Input
				{...register('password', {
					required: 'Please fill out this field.',
					minLength: {
						value: MIN_VALUE,
						message: `Please lengthen this text to 5 characters or more (you are currently using ${
							currentPasswordInput.length
						} characters).`,
					},
				})}
				errorMessage={errors?.password?.message}
				id='CustomerPassword'
				name='password'
				placeholder='Password'
				type='password'
				withIcon
			/>
			<Input
				{...register('confirmPassword', {
					required: 'Please fill out this field.',
					minLength: {
						value: MIN_VALUE,
						message: `Please lengthen this text to 5 characters or more (you are currently using ${
							currentPasswordInput.length
						} characters).`,
					},
					validate: value => value === currentPasswordInput || 'Passwords do not match.',
				})}
				errorMessage={errors?.confirmPassword?.message}
				id='CustomerPasswordConfirm'
				name='confirmPassword'
				placeholder='Confirm Password'
				type='password'
				withIcon
			/>

			<div className={styles.buttons}>
				<Button
					showSpinner={loading}
					data-login-button
					fullWidth
					type='submit'
					label='Activate Account'
					disabled={!(currentPasswordInput.length >= MIN_VALUE) || !(confirmPasswordInput === currentPasswordInput)}
				/>
				<Button color='white' fullWidth label='Decline Invitation' href='/' />
			</div>
		</form>
	);
};

export default ActiveAccountForm;
